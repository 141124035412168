import React, { ReactElement } from "react";

type Props = {
  className?: string;
};

export default function IconLink(props: Props): ReactElement<Props> {
  const { className = "" } = props;

  return (
    <svg
      viewBox="0 0 512.42 512.42"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M437.33 75.08c-100.1-100.1-262.13-100.12-362.25 0-100.1 100.1-100.12 262.14 0 362.25 100.1 100.1 262.14 100.12 362.25 0 100.1-100.1 100.12-262.13 0-362.25zm-10.7 325.74a256.76 256.76 0 00-38.27-28.33c8.92-30.81 14.2-64.72 15.46-99.96h75.3a221.48 221.48 0 01-52.5 128.3zM33.29 272.53h75.31c1.25 35.24 6.53 69.15 15.45 99.96a256.76 256.76 0 00-38.26 28.33 221.49 221.49 0 01-52.5-128.29zM85.8 111.6a256.76 256.76 0 0038.26 28.33c-8.92 30.81-14.2 64.71-15.45 99.95H33.3A221.49 221.49 0 0185.78 111.6zm154.1 31.7a221.77 221.77 0 01-76.1-19.27c13.85-35.64 39.36-78.39 76.1-88.98zm0 32.71v63.88h-98.63c1.13-29.82 5.35-58.44 12.38-84.64a254.44 254.44 0 0086.25 20.76zm0 96.52v63.88a254.44 254.44 0 00-86.25 20.75c-7.03-26.19-11.25-54.82-12.38-84.63zm0 96.58v108.26c-36.74-10.6-62.25-53.34-76.1-88.98a221.77 221.77 0 0176.1-19.28zm32.64 0c26.62 1.9 52.3 8.46 76.09 19.28-13.84 35.64-39.35 78.38-76.09 88.98zm0-32.7v-63.88h98.63c-1.13 29.81-5.36 58.44-12.38 84.63a254.44 254.44 0 00-86.25-20.76zm0-96.52V176a254.44 254.44 0 0086.25-20.76c7.02 26.2 11.25 54.82 12.38 84.64zm0-96.59V35.05c36.74 10.6 62.25 53.34 76.09 88.98a221.8 221.8 0 01-76.09 19.27zm73.32-91.95a222.52 222.52 0 0157.87 36.95 224.36 224.36 0 01-26.26 19.93c-8.14-20.15-18.54-39.74-31.6-56.88zm-210.89 56.88A224.44 224.44 0 01108.7 88.3a222.52 222.52 0 0157.86-36.95c-13.06 17.15-23.46 36.73-31.6 56.88zm0 295.96c8.14 20.15 18.54 39.73 31.6 56.88a222.52 222.52 0 01-57.86-36.95 224.36 224.36 0 0126.26-19.93zm242.5 0a224.46 224.46 0 0126.26 19.93 222.52 222.52 0 01-57.87 36.95c13.07-17.14 23.47-36.73 31.6-56.88zm26.36-164.3c-1.25-35.25-6.54-69.15-15.45-99.96a256.81 256.81 0 0038.26-28.33 221.49 221.49 0 0152.5 128.29z" />
    </svg>
  );
}
