import styles from "./Badge.module.css";

import React, { ReactElement } from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  size?: "s" | "m";
  text: string;
};

export default function Badge(props: Props): ReactElement<Props> {
  const { className, size = "m", text } = props;
  const containerClassName = classnames(styles.container, className, {
    [styles.sizeS]: size === "s",
    [styles.sizeM]: size === "m",
  });

  return <div className={containerClassName}>{text}</div>;
}
