import styles from "./posts.module.css";

import React, { ReactElement } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PostItem from "../components/PostItem";
import SEO from "../components/SEO";
import Text from "../components/Text";

type Props = {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            timeToRead: number;
            fields: {
              slug: string;
            };
            frontmatter: {
              date: string;
              title: string;
              description: string;
              tags: string[];
              github?: string;
              url?: string;
            };
          };
        },
      ];
    };
  };
};

export default function PagePosts(props: Props): ReactElement<Props> {
  const { data } = props;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title="All Posts" />
      <Text tag="h2" size={28} weight={700}>
        All Posts
      </Text>
      {posts.map(({ node }) => {
        return (
          <PostItem
            {...node}
            key={node.fields.slug}
            className={styles.postItem}
          />
        );
      })}
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            description
            tags
            github
            url
          }
        }
      }
    }
  }
`;
