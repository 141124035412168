import styles from "./PostItem.module.css";

import React, { ReactElement } from "react";
import { Link } from "gatsby";

import Badge from "./Badge";
import Text from "./Text";
import IconGithub from "./IconGithub";
import IconLink from "./IconLink";

type Props = {
  className?: string;
  timeToRead: number;
  fields: {
    slug: string;
  };
  frontmatter: {
    date: string;
    title: string;
    description: string;
    tags: string[];
    github?: string;
    url?: string;
  };
};

export default function PostItem(props: Props): ReactElement<Props> {
  const {
    className = "",
    timeToRead,
    fields: { slug },
    frontmatter: {
      title,
      description,
      date,
      tags,
      github: projectGithub,
      url: projectUrl,
    },
  } = props;

  return (
    <article className={className}>
      <header>
        <Text tag="h3" weight={700} size={22}>
          <Link to={slug}>{title}</Link>
        </Text>
        <div className={styles.subtitle}>
          <Text size={14} className={styles.date}>
            {`${date} • ${timeToRead} min read`}
          </Text>
          {projectGithub != null ? (
            <a
              href={projectGithub}
              className={styles.subtitleLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconGithub className={styles.subtitleLinkIcon} />
            </a>
          ) : null}
          {projectUrl != null ? (
            <a
              href={projectUrl}
              className={styles.subtitleLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLink className={styles.subtitleLinkIcon} />
            </a>
          ) : null}
        </div>
      </header>
      <section>
        <Text>{description}</Text>
      </section>
      <footer>
        <div className={styles.tags}>
          {tags.map((tag, index) => (
            <Link
              to={`/tag/${tag
                .toLowerCase()
                .split(" ")
                .join("-")}/`}
              key={index}
              className={styles.tag}
            >
              <Badge text={tag} size="s" />
            </Link>
          ))}
        </div>
      </footer>
    </article>
  );
}
