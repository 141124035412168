import React, { ReactElement } from "react";

type Props = {
  className?: string;
};

export default function IconGithub(props: Props): ReactElement<Props> {
  const { className = "" } = props;

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12 .5C5.4.5 0 5.8 0 12.3c0 5.2 3.4 9.6 8.2 11.2.6 0 .8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.3-1.4-1.7-1.4-1.7-1-.7.1-.7.1-.7C5 16.9 5.6 18 5.6 18c1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-5.8 0-1.3.5-2.4 1.3-3.2-.2-.3-.6-1.5 0-3.1 0 0 1-.3 3.4 1.2a11.7 11.7 0 016 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.1.9.8 1.3 1.9 1.3 3.2 0 4.5-2.8 5.5-5.5 5.8.5.3.9 1 .9 2.2v3.2c0 .3.1.7.8.6 4.8-1.6 8.2-6 8.2-11.2C24 5.8 18.6.5 12 .5z" />
    </svg>
  );
}
